var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-breadcrumb',{attrs:{"items":_vm.breadcrumb}}),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card-actions',{ref:"filters",staticClass:"form-filters",attrs:{"title":"Claude - Arquivo de categorias","action-close":false,"action-refresh":false,"action-collapse":true},on:{"refresh":function($event){return _vm.refreshStop('cardAction')}}},[_c('validation-observer',{ref:"rulesClaude"},[_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nome do produto:","label-for":"api_claude_name"}},[_c('b-form-input',{attrs:{"id":"api_claude_name","autocomplete":"off"},model:{value:(_vm.runtimeFilters.name),callback:function ($$v) {_vm.$set(_vm.runtimeFilters, "name", $$v)},expression:"runtimeFilters.name"}}),_c('small',[_vm._v("Identifica a categoria de um produto, conforme a árvore do Hafidme, utilizando a API intermediária do Claude.")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-button',{staticClass:"mt-2",attrs:{"id":"btn-buscar","variant":"primary"},on:{"click":function($event){return _vm.getApiClaude()}}},[_vm._v(" Buscar ")])],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[(_vm.isLoading)?_c('b-spinner',{attrs:{"label":"Loading..."}}):_c('div',[_c('json-viewer',{attrs:{"value":_vm.results,"expand-depth":5,"copyable":"","boxed":"","sort":""}})],1)],1)],1)],1),_c('b-card-actions',{ref:"filters",staticClass:"form-filters",attrs:{"title":"GPT - 1 Nível ","action-close":false,"action-refresh":false,"action-collapse":true},on:{"refresh":function($event){return _vm.refreshStop('cardAction')}}},[_c('small',[_vm._v(" Consulta feita perguntando 1 nível de categoria por vez."),_c('br'),_vm._v(" Ex: Qual a melhor categoria para o produto XXX. Categorias: 'Eletrônicos, Áudio e Vídeo', 'Câmeras e Acessórios', 'Celulares e Telefones', 'Animais', etc..."),_c('br'),_vm._v(" Qual e melhor categoria de segundo nível para o produto XXX. Categorias: 'Mercearia', 'Bebidas', 'Comida Preparada' E assim por adiante até finalizar todos os níveis de categoria. ")]),_c('validation-observer',{ref:"rulesGpt"},[_c('b-row',{staticClass:"mt-1"},[_c('b-col',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nome do produto:","label-for":"api_gpt_name"}},[_c('b-form-input',{attrs:{"id":"api_gpt_name","autocomplete":"off"},model:{value:(_vm.runtimeFilters.gpt_name),callback:function ($$v) {_vm.$set(_vm.runtimeFilters, "gpt_name", $$v)},expression:"runtimeFilters.gpt_name"}}),_c('small',[_vm._v("Identifica a categoria de um produto, conforme a árvore do Hafidme, utilizando a API intermediária do GPT.")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-button',{staticClass:"mt-2",attrs:{"id":"btn-buscar","variant":"primary"},on:{"click":function($event){return _vm.getApiGpt()}}},[_vm._v(" Buscar ")])],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[(_vm.isLoadingGpt)?_c('b-spinner',{attrs:{"label":"Loading..."}}):_c('div',[_c('json-viewer',{attrs:{"value":_vm.resultsGpt,"expand-depth":5,"copyable":"","boxed":"","sort":""}})],1)],1)],1)],1),_c('b-card-actions',{ref:"filters",staticClass:"form-filters",attrs:{"title":"Leveshtain","action-close":false,"action-refresh":false,"action-collapse":true},on:{"refresh":function($event){return _vm.refreshStop('cardAction')}}},[_c('small',[_vm._v(" Consulta feita pelo algoritmo Leveshtain, por aproximação da palavra/frase"),_c('br')]),_c('validation-observer',{ref:"rulesGptV2"},[_c('b-row',{staticClass:"mt-1"},[_c('b-col',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nome do produto:","label-for":"gpt_name_v2"}},[_c('b-form-input',{attrs:{"id":"gpt_name_v2","autocomplete":"off"},model:{value:(_vm.runtimeFilters.gpt_name_v2),callback:function ($$v) {_vm.$set(_vm.runtimeFilters, "gpt_name_v2", $$v)},expression:"runtimeFilters.gpt_name_v2"}}),_c('small',[_vm._v("Identifica a categoria de um produto, conforme a árvore do Hafidme, utilizando a API intermediária do GPT.")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-button',{staticClass:"mt-2",attrs:{"id":"btn-buscar","variant":"primary"},on:{"click":function($event){return _vm.getApiGptV2()}}},[_vm._v(" Buscar ")])],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[(_vm.isLoadingGptV2)?_c('b-spinner',{attrs:{"label":"Loading..."}}):_c('div',[_c('json-viewer',{attrs:{"value":_vm.resultsGptV2,"expand-depth":5,"copyable":"","boxed":"","sort":""}})],1)],1)],1)],1),_c('b-card-actions',{ref:"filters",staticClass:"form-filters",attrs:{"title":"GPT - Multi níveis","action-close":false,"action-refresh":false,"action-collapse":true},on:{"refresh":function($event){return _vm.refreshStop('cardAction')}}},[_c('small',[_vm._v(" Consulta feita perguntando uma categoria por vez, pedindo os 2 melhores resultados a cada novo nível e consultado até finalizar as possibilidades."),_c('br')]),_c('validation-observer',{ref:"rulesGptV3"},[_c('b-row',{staticClass:"mt-1"},[_c('b-col',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nome do produto:","label-for":"gpt_name_v3"}},[_c('b-form-input',{attrs:{"id":"gpt_name_v3","autocomplete":"off"},model:{value:(_vm.runtimeFilters.gpt_name_v3),callback:function ($$v) {_vm.$set(_vm.runtimeFilters, "gpt_name_v3", $$v)},expression:"runtimeFilters.gpt_name_v3"}}),_c('small',[_vm._v("Identifica a categoria de um produto, conforme a árvore do Hafidme, utilizando a API intermediária do GPT.")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-button',{staticClass:"mt-2",attrs:{"id":"btn-buscar","variant":"primary"},on:{"click":function($event){return _vm.getApiGptV3()}}},[_vm._v(" Buscar ")])],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[(_vm.isLoadingGptV3)?_c('b-spinner',{attrs:{"label":"Loading..."}}):_c('div',[_c('json-viewer',{attrs:{"value":_vm.resultsGptV3,"expand-depth":5,"copyable":"","boxed":"","sort":""}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }