<template>
  <section>
    <b-breadcrumb
      :items="breadcrumb"
    />

    <b-row>
      <b-col cols="12">
        <b-card-actions
          ref="filters"
          title="Claude - Arquivo de categorias"
          class="form-filters"
          :action-close="false"
          :action-refresh="false"
          :action-collapse="true"
          @refresh="refreshStop('cardAction')"
        >
          <validation-observer ref="rulesClaude">
            <b-row>
              <b-col>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label="Nome do produto:"
                    label-for="api_claude_name"
                  >
                    <b-form-input
                      id="api_claude_name"
                      v-model="runtimeFilters.name"
                      autocomplete="off"
                    />
                    <small>Identifica a categoria de um produto, conforme a árvore do Hafidme, utilizando a API intermediária do Claude.</small>
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <b-col cols="2">
                <b-button
                  id="btn-buscar"
                  variant="primary"
                  class="mt-2"
                  @click="getApiClaude()"
                >
                  Buscar
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>

          <b-row>
            <b-col cols="12">
              <b-spinner
                v-if="isLoading"
                label="Loading..."
              />
              <div v-else>
                <json-viewer
                  :value="results"
                  :expand-depth="5"
                  copyable
                  boxed
                  sort
                ></json-viewer>
              </div>
            </b-col>
          </b-row>
        </b-card-actions>

        <!-- GPT 1 nível -->
        <b-card-actions
          ref="filters"
          title="GPT - 1 Nível "
          class="form-filters"
          :action-close="false"
          :action-refresh="false"
          :action-collapse="true"
          @refresh="refreshStop('cardAction')"
        >
          <small>
            Consulta feita perguntando 1 nível de categoria por vez.<br />
            Ex: Qual a melhor categoria para o produto XXX. Categorias: 'Eletrônicos, Áudio e Vídeo', 'Câmeras e Acessórios', 'Celulares e Telefones', 'Animais', etc...<br />
            Qual e melhor categoria de segundo nível para o produto XXX. Categorias: 'Mercearia', 'Bebidas', 'Comida Preparada'
            E assim por adiante até finalizar todos os níveis de categoria.
          </small>
          <validation-observer ref="rulesGpt">
            <b-row class="mt-1">
              <b-col>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label="Nome do produto:"
                    label-for="api_gpt_name"
                  >
                    <b-form-input
                      id="api_gpt_name"
                      v-model="runtimeFilters.gpt_name"
                      autocomplete="off"
                    />
                    <small>Identifica a categoria de um produto, conforme a árvore do Hafidme, utilizando a API intermediária do GPT.</small>
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <b-col cols="2">
                <b-button
                  id="btn-buscar"
                  variant="primary"
                  class="mt-2"
                  @click="getApiGpt()"
                >
                  Buscar
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>

          <b-row>
            <b-col cols="12">
              <b-spinner
                v-if="isLoadingGpt"
                label="Loading..."
              />
              <div v-else>
                <json-viewer
                  :value="resultsGpt"
                  :expand-depth="5"
                  copyable
                  boxed
                  sort
                ></json-viewer>
              </div>
            </b-col>
          </b-row>
        </b-card-actions>

        <!-- Leveshtain -->
        <b-card-actions
          ref="filters"
          title="Leveshtain"
          class="form-filters"
          :action-close="false"
          :action-refresh="false"
          :action-collapse="true"
          @refresh="refreshStop('cardAction')"
        >
          <small>
            Consulta feita pelo algoritmo Leveshtain, por aproximação da palavra/frase<br />
          </small>
          <validation-observer ref="rulesGptV2">
            <b-row class="mt-1">
              <b-col>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label="Nome do produto:"
                    label-for="gpt_name_v2"
                  >
                    <b-form-input
                      id="gpt_name_v2"
                      v-model="runtimeFilters.gpt_name_v2"
                      autocomplete="off"
                    />
                    <small>Identifica a categoria de um produto, conforme a árvore do Hafidme, utilizando a API intermediária do GPT.</small>
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <b-col cols="2">
                <b-button
                  id="btn-buscar"
                  variant="primary"
                  class="mt-2"
                  @click="getApiGptV2()"
                >
                  Buscar
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>

          <b-row>
            <b-col cols="12">
              <b-spinner
                v-if="isLoadingGptV2"
                label="Loading..."
              />
              <div v-else>
                <json-viewer
                  :value="resultsGptV2"
                  :expand-depth="5"
                  copyable
                  boxed
                  sort
                ></json-viewer>
              </div>
            </b-col>
          </b-row>
        </b-card-actions>

        <!-- GPT multi niveis -->
        <b-card-actions
          ref="filters"
          title="GPT - Multi níveis"
          class="form-filters"
          :action-close="false"
          :action-refresh="false"
          :action-collapse="true"
          @refresh="refreshStop('cardAction')"
        >
          <small>
            Consulta feita perguntando uma categoria por vez, pedindo os 2 melhores resultados a cada novo nível e consultado até finalizar as possibilidades.<br />
          </small>
          <validation-observer ref="rulesGptV3">
            <b-row class="mt-1">
              <b-col>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label="Nome do produto:"
                    label-for="gpt_name_v3"
                  >
                    <b-form-input
                      id="gpt_name_v3"
                      v-model="runtimeFilters.gpt_name_v3"
                      autocomplete="off"
                    />
                    <small>Identifica a categoria de um produto, conforme a árvore do Hafidme, utilizando a API intermediária do GPT.</small>
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <b-col cols="2">
                <b-button
                  id="btn-buscar"
                  variant="primary"
                  class="mt-2"
                  @click="getApiGptV3()"
                >
                  Buscar
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>

          <b-row>
            <b-col cols="12">
              <b-spinner
                v-if="isLoadingGptV3"
                label="Loading..."
              />
              <div v-else>
                <json-viewer
                  :value="resultsGptV3"
                  :expand-depth="5"
                  copyable
                  boxed
                  sort
                ></json-viewer>
              </div>
            </b-col>
          </b-row>
        </b-card-actions>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BBreadcrumb,
  BButton,
  BCol,
  BFormGroup,
  BFormInput,
  BRow,
  BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { regex } from '@validations'
import toast from '@/mixins/toast'
import SusiService from '@/services/susiService'
import JsonViewer from 'vue-json-viewer'

export default {
  components: {
    BBreadcrumb,
    BFormInput,
    BButton,
    BCardActions,
    BCol,
    BFormGroup,
    BRow,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    JsonViewer,
  },
  mixins: [
    toast,
  ],
  data() {
    return {
      regex,

      breadcrumb: [
        {
          text: 'Dashboard',
          to: { name: 'cms-dashboard' },
        },
        {
          text: 'Ferramentas',
          active: true,
        },
        {
          text: 'Testes Diversos',
          active: true,
        },
      ],
      runtimeFilters: {
        name: '',
        gpt_name: '',
        gpt_name_v2: '',
        gpt_name_v3: '',
      },
      isLoading: false,
      isLoadingGpt: false,
      isLoadingGptV2: false,
      isLoadingGptV3: false,
      results: [],
      resultsGpt: [],
      resultsGptV2: [],
      resultsGptV3: [],
    }
  },
  created() {
  },
  methods: {
    reset() {
      this.runtimeFilters = Object.fromEntries(Object.entries(this.runtimeFilters).map(item => {
        // eslint-disable-next-line no-param-reassign
        item[1] = ''
        return item
      }))
    },

    getApiClaude() {
      this.$refs.rulesClaude.validate().then(success => {
        if (success) {
          this.isLoading = true

          SusiService.getClaudeCategory(this.runtimeFilters.name)
            .then(response => {
              this.results = response.data.data
            })
            .catch(() => {
            })
            .finally(() => {
              this.isLoading = false
            })
        }
      })
    },

    getApiGpt() {
      this.$refs.rulesGpt.validate().then(success => {
        if (success) {
          this.isLoadingGpt = true

          // adaptado o GTP na API do claude, pra não precisar criar uma api do GPT
          SusiService.getGptCategory(this.runtimeFilters.gpt_name)
            .then(response => {
              this.resultsGpt = response.data.data
            })
            .catch(() => {
            })
            .finally(() => {
              this.isLoadingGpt = false
            })
        }
      })
    },

    getApiGptV3() {
      this.$refs.rulesGptV3.validate().then(success => {
        if (success) {
          this.isLoadingGptV3 = true

          // adaptado o GTP na API do claude, pra não precisar criar uma api do GPT
          SusiService.getGptCategoryV3(this.runtimeFilters.gpt_name_v3)
            .then(response => {
              this.resultsGptV3 = response.data.data
            })
            .catch(() => {
            })
            .finally(() => {
              this.isLoadingGptV3 = false
            })
        }
      })
    },

    getApiGptV2() {
      this.$refs.rulesGptV2.validate().then(success => {
        if (success) {
          this.isLoadingGptV2 = true

          // adaptado o GTP na API do claude, pra não precisar criar uma api do GPT
          SusiService.getGptCategoryV2(this.runtimeFilters.gpt_name_v2)
            .then(response => {
              this.resultsGptV2 = response.data.data
            })
            .catch(() => {
            })
            .finally(() => {
              this.isLoadingGptV2 = false
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';

  .text-danger{
      font-weight: bold;
  }
</style>
